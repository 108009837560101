.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header .cell {
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-header .table {
  margin-bottom: 50px;
}

.App-footer {
  background-color: #282c34;
  color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-footer h6 {
  max-width: 300px;
}
